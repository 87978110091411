<template>
    <div>
        Downloading file with id - <strong>{{ $route.params.fileId }}</strong>
    </div>
</template>

<script>
export default {
  async mounted() {
    await this.downloadFile();
    // window.close();
  },
  methods: {
    async downloadFile() {
      await this.$store.dispatch('downloadFileUsingOpenAIFileId', this.$route.params.fileId);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
